/* unplugin-vue-components disabled */export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "pl": {
        "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiń"])},
        "getStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaczynamy"])}
      },
      "en": {
        "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
        "getStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])}
      }
    }
  })
}