/* unplugin-vue-components disabled */export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "pl": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz pełne moliwości"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinie na żywo. Mapa najbliższych miejsc. Koniec złych wyborów."])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj z E-mailem"])},
        "continueWithGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj z Google"])},
        "continueWithApple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj z Apple"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z nami."])}
      },
      "en": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the full experience"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live reviews. A map of the closest places. No more bad choices."])},
        "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Email"])},
        "continueWithGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Google"])},
        "continueWithApple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Apple"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again or contact us."])}
      }
    }
  })
}