/* unplugin-vue-components disabled */import script from "./walkthrough.vue?vue&type=script&setup=true&lang=ts"
export * from "./walkthrough.vue?vue&type=script&setup=true&lang=ts"

import "./walkthrough.vue?vue&type=style&index=0&id=31e7600a&lang=css"
/* custom blocks */
import block0 from "./walkthrough.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__