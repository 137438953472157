import "core-js/modules/es.object.from-entries.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { computed, ref } from 'vue';
import avolittyHasher from 'avolitty-hasher';
import { singular } from "pluralize";
import { toRefs } from "@vueuse/core";
import { useFetch } from "./fetch";
import { useClient } from './client';
import { isEmpty, omit } from 'radash';
import { DateTime } from 'luxon';
function hash(data) {
  return avolittyHasher(data, {
    ignoreObjectKeySortOrder: true,
    outputLength: 8
  });
}
export class BaseClass {
  constructor(data) {
    Object.assign(this, data);
  }
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function useIndex({
  entity,
  endpoint = entity,
  single = {},
  collection = {},
  realtime = false,
  withInstancesOf
}) {
  const requests = ref({});
  const indexItems = ref({});
  const realtimeCallbacks = [];
  function indexUpdate(items) {
    indexItems.value = {
      ...indexItems.value,
      ...Object.fromEntries(items.map(item => [item.id, new withInstancesOf(item)]))
    };
  }
  function indexRemove(id) {
    indexItems.value = Object.fromEntries(Object.entries(indexItems.value).filter(([key]) => key !== id));
  }
  function useItems({
    page: initialPage = 1,
    perPage: initialPerPage = 25,
    ...restOfParameters
  } = {}) {
    const parameters = ref({
      per_page: initialPerPage,
      page: initialPage,
      ...restOfParameters
    });
    const checksum = computed(() => hash(parameters.value));
    const items = computed(() => {
      const fetchObject = requests.value[checksum.value];
      if (!fetchObject) return [];
      const buffer = [];
      for (const id of fetchObject.ids) {
        const item = indexItems.value[id];
        if (!item) continue;
        if (!itemMatchesFilters(item, parameters)) continue;
        buffer.push(new withInstancesOf(item));
      }
      return buffer;
    });
    const meta = computed(() => {
      var _requests$value$check;
      return (_requests$value$check = requests.value[checksum.value]) === null || _requests$value$check === void 0 ? void 0 : _requests$value$check.meta;
    });
    const pending = computed(() => items.value.length ? false : fetchPending.value);
    const {
      refetch,
      pending: fetchPending
    } = useFetch({
      endpoint,
      params: parameters,
      callback(response, context) {
        requests.value = {
          ...requests.value,
          [hash(context)]: {
            ids: response.data.map(item => item.id),
            meta: response.meta
          }
        };
        indexUpdate(response.data);
      }
    });
    realtimeCallbacks.push(item => {
      const checksumKey = hash(parameters.value);
      const fetchObject = requests.value[checksumKey];
      if (!itemMatchesFilters(item, parameters)) return;
      const ids = [...new Set([...fetchObject.ids, item.id])];
      requests.value = {
        ...requests.value,
        [checksumKey]: {
          ids,
          meta: fetchObject.meta
        }
      };
    });
    const {
      page,
      per_page: perPage
    } = toRefs(parameters);
    return {
      items,
      pending,
      meta,
      page,
      perPage,
      refetch,
      params: parameters
    };
  }
  const supabase = useClient();
  if (realtime) supabase.channel([entity, `listen`].join(`-`)).on(`postgres_changes`, {
    event: `*`,
    schema: `public`,
    table: entity
  }, payload => {
    if (isEmpty(payload.new)) return;
    const updatedItem = payload.new;
    indexUpdate([updatedItem]);
    for (const callback of realtimeCallbacks) callback(updatedItem);
  }).subscribe();
  return {
    useItems,
    byId: id => {
      const {
        items,
        pending,
        ...context
      } = useItems({
        "id.eq": id
      });
      const rawId = () => isRef(id) ? id.value : id;
      return {
        item: computed(() => {
          var _indexItems$value$raw;
          return (_indexItems$value$raw = indexItems.value[rawId()]) !== null && _indexItems$value$raw !== void 0 ? _indexItems$value$raw : items.value[0];
        }),
        pending: computed(() => {
          var _indexItems$value$raw2;
          return (_indexItems$value$raw2 = !indexItems.value[rawId()]) !== null && _indexItems$value$raw2 !== void 0 ? _indexItems$value$raw2 : pending.value;
        }),
        ...context
      };
    },
    byIds: ids => {
      const {
        items,
        pending,
        ...context
      } = useItems({
        "id.in": `(${[...ids].join(`,`)})`
      });
      const preItems = [];
      for (const id of ids) indexItems.value[id] && preItems.push(indexItems.value[id]);
      return {
        items: computed(() => items.value.length === 0 ? preItems : items.value),
        pending: computed(() => preItems.length === 0 && pending.value),
        ...context
      };
    },
    single,
    collection,
    singular: singular(entity)
  };
}
const valueMatches = {
  eq: (fieldValue, filterValue) => fieldValue === filterValue,
  in: (fieldValue, filterValue) => filterValue.replace(`(`, ``).replace(`)`, ``).split(`,`).includes(fieldValue),
  gt: (fieldValue, filterValue) => typeof filterValue === `string` && typeof fieldValue === `string` ? DateTime.fromISO(fieldValue).diff(DateTime.fromISO(filterValue)).milliseconds > 0 : fieldValue > filterValue,
  lte: (fieldValue, filterValue) => typeof filterValue === `string` && typeof fieldValue === `string` ? DateTime.fromISO(fieldValue).diff(DateTime.fromISO(filterValue)).milliseconds <= 0 : fieldValue <= filterValue,
  ov: (fieldValue, filterValue) => {
    if (!filterValue) return true;
    const deserializedFilterValue = JSON.parse(filterValue.replace(/{/g, `[`).replace(/}/g, `]`));
    for (const value of deserializedFilterValue) if (fieldValue.includes(value)) return true;
    return false;
  }
};
const itemMatchesFilters = (item, parameters) => {
  for (const property in omit(parameters.value, [`page`, `per_page`])) {
    if (!property.includes(`.`)) continue;
    const [key, op] = property.split(`.`);
    const value = parameters.value[property];
    if (!valueMatches[op](item[key], value)) return false;
  }
  return true;
};