import { ObjectDirective } from "vue";
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { debounce } from "radash";

export const debouncedHaptic = debounce({ delay: 100 }, () => Haptics.impact({ style: ImpactStyle.Light }));

const vHover: ObjectDirective<HTMLElement> = {
    mounted(element) {
        element.__hoverCallback = () => {
            element.style.transition = `transform .3s`;
            element.style.transform = `scale(.875)`;
            debouncedHaptic();

            document.addEventListener(`touchend`, () => {
                debouncedHaptic();
                element.style.transform = `none`;
            }, { once: true });
        };

        element.addEventListener(`touchstart`, element.__hoverCallback);
    },

    beforeUnmount(element, binding, vnode, previousVnode) {
        element.removeEventListener(`touchstart`, element.__hoverCallback);
    },
};

export default vHover;
