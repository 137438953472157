import { Ref, ref, watch } from 'vue';
import { Database } from '../../../types';
import { useClient } from './client';
import { omit } from 'radash';

interface Context<T> {
    endpoint: string,
    params: Ref<Record<string, any>>,
    callback: (argument1: { data: T[], meta: any }, argument2: Record<string, any>) => void
}

export function useFetch<T extends keyof Database[`public`][`Tables`]>({
    endpoint,
    params,
    callback,
}: Context<Database[`public`][`Tables`][T][`Row`]>) {
    type U = Database[`public`][`Tables`][T][`Row`];
    const supabase = useClient();

    let _abortController = new AbortController();
    let _initialized = false;

    const items: Ref<U[]> = ref([]);
    const pending = ref(true);
    const meta: Ref<{ count: number } | undefined> = ref();

    const builderCreator = () => supabase.from(endpoint).select(`*`, { count: `exact` });

    const fetch = async() => {
        let builder = builderCreator();

        if (params.value.query) {
            const query = params.value.query as string;
            const ftsCreator = () => builderCreator().textSearch(`fts`, `'${query}'`, { type: `websearch`, config: `english` });
            const response = await ftsCreator();

            builder = response.count ? ftsCreator() : builder.or([`name.ilike.`, `%`, query, `%`].join(``));
        }

        for (const property in omit(params.value, [`page`, `per_page`, `query`])) {
            if (!params.value[property])
                continue;

            builder = builder.or([property, params.value[property]].join(`.`));
        }

        return builder.then(
            ({ data, count }) => {
                items.value = data! as U[];
                meta.value = { count: count! };

                _initialized = true;
                callback({ data: data! as U[], meta: { count } }, params.value);
                pending.value = false;
            },
        );

    };

    watch(params, (now, then) => {
        if (_initialized)
            _abortController.abort();

        _abortController = new AbortController();

        setTimeout(fetch, 0);
    }, { deep: true });

    const promise = new Promise(resolve => {
        setTimeout(() => fetch().then(resolve), 0);
    });

    return { items, meta, refetch: fetch, pending };
}
