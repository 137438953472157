import { computed, ref, Ref } from 'vue';
import { useClient } from './client';
import { useRouter } from 'vue-router';
import * as users from '@core/supabase/user';

interface User {
    id: string;
    email: string;
    providers?: string[]; 
}

export const userId = ref<string | undefined>();

export const profile = computed(
    () => userId.value ? users.byId(userId as Ref<string>) : undefined,
);

const useUser = () => {
    const user = ref<User>({ id: ``, email: ``, providers: [] }); 
    const authenticated = ref(`unknown` as `unknown` | boolean);
    const router = useRouter();

    const fetchUser = () => {
        const client = useClient();
        client.auth.getUser().then(response => {
            const data = response.data;
            if (data.user) {
                const { id, email, identities } = data.user;
                user.value = { id, email: email ?? `` };
                if (identities) {
                    const providers = identities.map(identity => identity.provider);
                    user.value.providers = providers;
                }
                userId.value = id;
            }
            authenticated.value = !!data.user;
        }).catch(() => authenticated.value = false);
    };
    
    const logout = async() => {
        const client = useClient();
        try {
            await client.auth.signOut();
            router.push(`/?skipwalkthrough=true`);

        } catch (error) {
            console.error(`Error signing out:`, error.message);
        }
    };

    fetchUser();

    return { user, logout, authenticated, profile };
};

export default useUser;
