import { createClient } from '@supabase/supabase-js';
import { Database } from '../../../types';

const client = createClient<Database>(
    process.env.VUE_APP_SUPABASE_URL as string,
    process.env.VUE_APP_SUPABASE_ANON_KEY as string,
);

export function useClient() {
    return client;
}
