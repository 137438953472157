import { Haptics } from '@capacitor/haptics';
import { toastController } from '@ionic/vue';

export const useToast = () => {
    const presentToast = async(message: string, duration: number, position: `top` | `middle` | `bottom`) => {
        const toast = await toastController.create({
            message: message,
            duration: duration,
            position: position,
            color: `primary`,
            cssClass: `custom-toast`,
            buttons: [
                {
                    text: `X`,
                    role: `cancel`,
                },
            ],
        });

        Haptics.notification();
        await toast.present();
    };

    return { presentToast };
};

