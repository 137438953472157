import _defineProperty from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.21.0/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { useClient } from './client';
import { useIndex } from './index';
export const {
  useItems,
  byId,
  single
} = useIndex({
  entity: `users`,
  realtime: true,
  withInstancesOf: class withInstancesOf {
    // eslint-disable-next-line semi
    constructor(data) {
      _defineProperty(this, "id", void 0);
      _defineProperty(this, "picture", void 0);
      _defineProperty(this, "username", void 0);
      _defineProperty(this, "favorites", void 0);
      Object.assign(this, data);
    }
    async update(item) {
      const client = useClient();
      return await client.from(`users`).update({
        ...item
      }).eq(`id`, this.id);
    }
    get capitalizedUsername() {
      return this.username.charAt(0).toUpperCase() + this.username.slice(1);
    }
  },
  single: {
    current: () => {
      const id = inject(`userId`);
      const cachedItem = inject(`user`);
      const {
        item
      } = byId(id);
      return item || cachedItem;
    }
  }
});